<template>
  <!--begin::Project Costing Internal Listing-->
  <v-container fluid>
    <v-container>
      <v-row>
        <v-col md="2" class="pl-0">
          <v-text-field
            v-model.trim="filter.search"
            v-on:click:clear="clearFilter('search')"
            clearable
            dense
            filled
            flat
            label="Search"
            hide-details
            solo
            :disabled="dataLoading"
            :loading="dataLoading"
            color="cyan"
            @keydown.enter="getAllProjectCostings"
            @keydown.tab="getAllProjectCostings"
          ></v-text-field>
        </v-col>
        <v-col md="2">
          <v-tooltip top content-class="custom-top-tooltip">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                class="mx-2 custom-bold-button white--text"
                color="cyan"
                tile
                :disabled="dataLoading"
                :loading="dataLoading"
                v-on:click="getAllProjectCostings"
              >
                <v-icon>mdi-magnify</v-icon>
              </v-btn>
            </template>
            <span>Search</span>
          </v-tooltip>
          <v-tooltip top content-class="custom-top-tooltip">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                class="mx-2 custom-bold-button white--text"
                color="cyan"
                tile
                :disabled="dataLoading"
                :loading="dataLoading"
                v-on:click="
                  $router.push(
                    getDefaultRoute('project.costing.create', {
                      query: { project: parent },
                    })
                  )
                "
              >
                <v-icon>mdi-plus</v-icon>
              </v-btn>
            </template>
            <span>Create New Project Costing</span>
          </v-tooltip>
        </v-col>
      </v-row>
    </v-container>
    <ListingTable
      :columnCount="6"
      :dataLoading="dataLoading"
      :rowData="dataRows"
    >
      <template v-slot:thead>
        <thead>
          <tr>
            <th class="simple-table-th" width="100">Costing #</th>
            <th class="simple-table-th" width="100">Reference #</th>
            <th class="simple-table-th" width="300">Basic Details</th>
            <th class="simple-table-th" width="300">Customer</th>
            <th class="simple-table-th" width="300">Quotation</th>
            <th class="simple-table-th" width="200">Amount</th>
          </tr>
        </thead>
      </template>
      <template v-slot:tbody>
        <tbody class="custom-border-bottom custom-border-top">
          <template v-if="dataRows.length > 0">
            <tr
              link
              :key="index"
              v-for="(row, index) in dataRows"
              v-on:click="
                $router.push(
                  getDefaultRoute('project.costing.detail', {
                    params: { id: row.id },
                  })
                )
              "
            >
              <td class="simple-table-td">
                <Barcode :barcode="row.barcode"></Barcode>
                <div v-on:click.stop>
                  <v-tooltip top content-class="custom-top-tooltip">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        v-bind="attrs"
                        v-on="on"
                        :disabled="!row.pdf_url"
                        color="cyan"
                        v-on:click.stop.prevent="downloadPdf(row)"
                        icon
                      >
                        <v-icon>mdi-file-pdf-box</v-icon>
                      </v-btn>
                    </template>
                    <span>Download PDF</span>
                  </v-tooltip>
                </div>
              </td>
              <td class="simple-table-td">
                <div class="projectcosting-listing-customer">
                  <p class="m-0 custom-nowrap-ellipsis">
                    <template v-if="row.soled_job_no">{{
                      row.soled_job_no
                    }}</template>
                    <template v-else
                      ><em class="text--secondary">No Reference #</em></template
                    >
                  </p>
                </div>
              </td>
              <td class="simple-table-td" width="100">
                <div class="projectcosting-listing-customer">
                  <p class="m-0 custom-nowrap-ellipsis">
                    <b>Customer PO No.: </b>
                    <template v-if="row.customer_po_no">{{
                      row.customer_po_no
                    }}</template>
                    <template v-else
                      ><em class="text--secondary"
                        >No Customer PO No.</em
                      ></template
                    >
                  </p>
                  <p class="m-0 custom-nowrap-ellipsis">
                    <b>Customer PO Date: </b>
                    <template v-if="row.customer_po_date">{{
                      formatDate(row.customer_po_date)
                    }}</template>
                    <template v-else
                      ><em class="text--secondary"
                        >No Customer PO Date</em
                      ></template
                    >
                  </p>
                </div>
              </td>
              <td class="simple-table-td" width="100">
                <div
                  class="projectcosting-listing-customer"
                  v-if="row.customer"
                >
                  <p class="m-0 custom-nowrap-ellipsis">
                    <b>Display Name: </b>
                    {{ row.customer.display_name }}
                  </p>
                  <p class="m-0 custom-nowrap-ellipsis">
                    <b>Company: </b> {{ row.customer.company_name }}
                  </p>
                  <p
                    class="m-0 custom-nowrap-ellipsis"
                    v-if="row.customer.default_person"
                  >
                    <b>Email: </b>
                    {{ row.customer.default_person.primary_email }}
                  </p>
                  <p
                    class="m-0 custom-nowrap-ellipsis"
                    v-if="row.customer.default_person"
                  >
                    <b>Phone: </b>
                    {{ row.customer.default_person.primary_phone }}
                  </p>
                </div>
              </td>
              <td class="simple-table-td" width="100">
                <div
                  class="projectcosting-listing-customer"
                  v-if="row.quotation"
                  v-on:click.stop.prevent="pushToQuotation(row.quotation)"
                >
                  <Barcode :barcode="row.quotation.barcode"></Barcode>
                  <p class="m-0 custom-nowrap-ellipsis">
                    <b>Job Title: </b>
                    <template v-if="row.quotation.job_title">{{
                      row.quotation.job_title
                    }}</template>
                    <template v-else
                      ><em class="text--secondary">No Job Title</em></template
                    >
                  </p>
                </div>
              </td>
              <td class="simple-table-td" width="100">
                <div class="projectcosting-listing-amount">
                  <p class="m-0 custom-nowrap-ellipsis">
                    <b>Total Price: </b>
                    {{ $accountingJS.formatMoney(row.total_price) }}
                  </p>
                  <p class="m-0 custom-nowrap-ellipsis">
                    <b>Total Cost: </b>
                    {{ $accountingJS.formatMoney(row.total_cost) }}
                  </p>
                  <p class="m-0 custom-nowrap-ellipsis">
                    <b>Profit: </b>
                    {{ $accountingJS.formatMoney(row.profit) }}
                  </p>
                  <p class="m-0 custom-nowrap-ellipsis">
                    <b>Profit Percentage: </b>
                    {{ $accountingJS.formatNumber(row.profit_percentage) }}%
                  </p>
                </div>
              </td>
            </tr>
          </template>
          <tr v-else-if="!dataLoading">
            <td colspan="6">
              <p
                class="m-0 row-not-found text-center font-weight-500 font-size-16"
              >
                <img
                  :src="$assetURL('media/error/empty.png')"
                  class="row-not-found-image"
                />
                Uhh... There are no project costing at the moment.
              </p>
            </td>
          </tr>
        </tbody>
      </template>
    </ListingTable>
  </v-container>
  <!--end::Project Costing Internal Listing-->
</template>

<script>
import { QUERY } from "@/core/services/store/request.module";
import Barcode from "@/view/pages/partials/Barcode.vue";
import CommonMixin from "@/core/plugins/common-mixin";
import ListingTable from "@/view/pages/partials/Listing-Table.vue";

export default {
  mixins: [CommonMixin],
  name: "project-costing-internal-list",
  data() {
    return {
      parent: 0,
      dataRows: [],
      dataLoading: true,
      filter: {
        status: "all",
        search: null,
      },
      timeout: null,
      timeoutLimit: 500,
    };
  },
  props: {
    detail: {
      type: Object,
      required: true,
      default: () => {
        return new Object();
      },
    },
    type: {
      type: String,
      required: true,
      default: "product",
    },
  },
  watch: {
    detail: {
      deep: true,
      immediate: true,
      handler(param) {
        this.parent = this.lodash.toSafeInteger(param.id);
        if (this.parent > 0) {
          this.getAllProjectCostings();
        }
      },
    },
  },
  components: {
    Barcode,
    ListingTable,
  },
  methods: {
    downloadPdf(row) {
      window.open(
        process.env.VUE_APP_API_URL + "project/costing/" + row.id + "/download",
        "_blank"
      );
    },
    pushToQuotation(quotation) {
      this.$router.push(
        this.getDefaultRoute("quotation.detail", {
          params: {
            id: quotation.id,
          },
        })
      );
    },
    clearFilter(field) {
      const _this = this;
      switch (field) {
        case "search":
          _this.filter.search = null;
          break;
        case "dates":
          _this.dates = [];
          break;
      }
      _this.getAllProjectCostings();
    },
    getAllProjectCostings() {
      const _this = this;

      let filter = {
        per_page: 10,
        current_page: 1,
        search: _this.filter.search || undefined,
      };

      clearTimeout(_this.timeout);
      _this.dataRows = [];
      _this.dataLoading = true;
      _this.timeout = setTimeout(function () {
        _this.$store
          .dispatch(QUERY, {
            url: "project/costing",
            data: filter,
          })
          .then(({ data }) => {
            _this.dataRows = data.rows;
          })
          .catch((error) => {
            _this.logError(error);
          })
          .finally(() => {
            _this.dataLoading = false;
          });
      }, _this.timeoutLimit);
    },
  },
};
</script>
